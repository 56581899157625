import React from 'react';
import trust from '../assets/pathway_home.webp';

const WhoWeAre = () => {
  return (
    <div className="mx-10 sm:mx-20 mt-20 ">
        {/* <h1 className='text-center text-3xl mb-10 sm:mb-20 border-b-4 w-fit m-auto border-[#DB9A48] '>Who we are</h1> */}
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
        
        <div>
          <h2 className="text-2xl sm:text-3xl font-bold mb-5  text-[#DB9A48]">Pathway Foundation</h2>
          <p className=" mb-5">
            Through excellent educational programs, Arogya Seva Mekha
            The development of the community and the comfort of the community
            It started its operations from 2020 with the aim of achieving Dhyamaka 
            Pathway is a Gtrade Educational & Charitable Trust
            Foundation.
          </p>
          <p className="mb-5">
            Within a short period of time, various educational programs - 
            Trust to implement health and social service schemes 
            It has been done. Varies from LP level to Degree
            Intellectual development in students of age, personality
            Four educational projects that enable self-development 
            is walking Women Empowerment, Age Friendly 
            Projects aimed at
          </p>
          <p className="mb-5">
            Nitya Royagika is also the needy in various sections of the society
            Free treatment system for the disabled, homecare, 
            Ambulance Service, Food Security Scheme, Physio
            Therapy Center and Counseling Health-Community 
            Notable projects in the service sector.
          </p>
          <p>
            Located in Chelannur Palath area of ​​Kozhikkakot district 
            Pathway center centrally functioning trust Prof
            Professional level, teaching and community service 
            A group active in activities are goodwill 
            Providing leadership.Zakat shares of goodwill
            Funds raised through te and charity
            This is what drives its activities forward. 
            A companion for you and your family on this mission
            Hope and prayers.
          </p>
        </div>

        <div>
          <img src={trust} alt="About Us" className=" h-[100%] w-auto m-auto object-cover " />
        </div>

      </div>
    </div>
  );
};

export default WhoWeAre;
