import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

const Menu = ({ closeMenu }) => {
  const [isProjectsOpen, setIsProjectsOpen] = useState(false);

  const handleProjectsClick = () => {
    setIsProjectsOpen(!isProjectsOpen);
  };

  const handleMenuClick = (e) => {
    if (!e.target.closest('.dropdown-menu')) {
      setIsProjectsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleMenuClick);
    return () => {
      document.removeEventListener('click', handleMenuClick);
    };
  }, []);

  const handleNavLinkClick = () => {
    closeMenu();
    setIsProjectsOpen(false);
  };

  return (
    <div className="fixed top-16 w-full z-40 bg-white shadow-md menu transition duration-300">
      <ul className="flex flex-col space-y-2 p-4 text-xl mt-5">
        <li>
          <NavLink to="/" className="block menu transition duration-300 hover:text-[#DB9A48]" onClick={handleNavLinkClick}>
            Home
          </NavLink>
        </li>
        <li className="relative dropdown-menu menu">
          <button className="block transition duration-300 hover:text-[#DB9A48] w-full text-left" onClick={handleProjectsClick}>
            Projects
            <svg
              className={`w-4 h-4 ml-1 inline ${isProjectsOpen ? 'transform rotate-180' : ''}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          {isProjectsOpen && (
            <ul className="pl-4 pt-2 space-y-2">
              <li className="ml-3">
                <NavLink to="/educational-projects" className="block transition duration-300 hover:text-[#DB9A48]" onClick={handleNavLinkClick}>
                  Educational Projects
                </NavLink>
              </li>
              <li className="ml-3">
                <NavLink to="/social-welfare-projects" className="block transition duration-300 hover:text-[#DB9A48]" onClick={handleNavLinkClick}>
                  Social Welfare Projects
                </NavLink>
              </li>
            </ul>
          )}
        </li>
        {/* <li>
          <NavLink to="/events" className="block transition duration-300 hover:text-[#DB9A48]" onClick={handleNavLinkClick}>
            Events
          </NavLink>
        </li> */}
        <li>
          <NavLink to="/gallery" className="block menu transition duration-300 hover:text-[#DB9A48]" onClick={handleNavLinkClick}>
            Gallery
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" className="block menu transition duration-300 hover:text-[#DB9A48]" onClick={handleNavLinkClick}>
            About
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" className="block menu transition duration-300 hover:text-[#DB9A48]" onClick={handleNavLinkClick}>
            Contact
          </NavLink>
        </li>
        <li>
          <div className="p-4">
            <Link
              to="/donate"
              className="bg-[#DB9A48] menu text-white px-5 py-2 rounded-xl hover:bg-white hover:text-[#DB9A48] border border-[#DB9A48] transition duration-300 block text-center"
              onClick={handleNavLinkClick}
            >
              Donate
            </Link>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
