import React, { useEffect, useState } from 'react';
import Modal from '../layouts/Modal';  
import gallery1 from '../assets/gallery/pathway_gallery_1.webp';
import gallery2 from '../assets/gallery/pathway_gallery_2.webp';
import gallery3 from '../assets/gallery/pathway_gallery_3.webp';
import gallery4 from '../assets/gallery/pathway_gallery_4.webp';
import gallery5 from '../assets/gallery/pathway_gallery_5.webp';
import gallery6 from '../assets/gallery/pathway_gallery_6.webp';
import gallery7 from '../assets/gallery/pathway_gallery_7.webp';
import gallery8 from '../assets/gallery/pathway_gallery_8.webp';
import gallery9 from '../assets/gallery/pathway_gallery_9.webp';
import gallery10 from '../assets/gallery/pathway_gallery_10.webp';
import gallery11 from '../assets/gallery/pathway_gallery_11.webp';
import gallery12 from '../assets/gallery/pathway_gallery_12.webp';
import gallery13 from '../assets/gallery/pathway_gallery_13.webp';
import gallery14 from '../assets/gallery/pathway_gallery_14.webp';
import gallery15 from '../assets/gallery/pathway_gallery_15.webp';
import gallery16 from '../assets/gallery/pathway_gallery_16.webp';
import gallery17 from '../assets/gallery/pathway_gallery_17.webp';
import gallery18 from '../assets/gallery/pathway_gallery_18.webp';
import gallery19 from '../assets/gallery/pathway_gallery_19.webp';
import gallery20 from '../assets/gallery/pathway_gallery_20.webp';
import gallery21 from '../assets/gallery/pathway_gallery_21.webp';
import gallery22 from '../assets/gallery/pathway_gallery_22.webp';
import gallery23 from '../assets/gallery/pathway_gallery_23.webp';
import gallery24 from '../assets/gallery/pathway_gallery_24.webp';

const images = [
  gallery1, gallery2, gallery3, gallery4, gallery5, gallery6,
  gallery7, gallery8, gallery9, gallery10, gallery11, gallery12,
  gallery13, gallery14, gallery15, gallery16, gallery17, gallery18, 
  gallery19, gallery20, gallery21, gallery22, gallery23, gallery24,
];

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="mt-32">
      <h1 className="text-center text-2xl min-[350px]:text-3xl mb-10 sm:mb-20 border-b-4 pb-2 w-fit m-auto border-[#DB9A48]">Explore our showcase</h1>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {images.map((image, index) => (
            <div key={index} className="overflow-hidden rounded-xl w-full h-[200px] sm:h-[250px]" onClick={() => openModal(image)}>
              <img src={image} alt={`Gallery ${index + 1}`} className="object-cover w-full h-full hover:scale-110 transition duration-300 cursor-pointer" />
            </div>
          ))}
        </div>
      </div>
          
      <Modal image={selectedImage} closeModal={closeModal}/>
    </div>
  );
}

export default Gallery;
