import React from 'react'
import ServiceCard from '../layouts/ServiceCard'
import edu from '../assets/pathway_eduproject.webp'
import sw from '../assets/pathway_swproject.webp'

const ServicesProvide = () => {
  return (
    <div className='mx-5 min-[450px]:mx-10 sm:mx-5 md:mx-20 lg:mx-40 mt-20 '>
      {/* <h1 className='text-center text-2xl min-[350px]:text-3xl mb-10 sm:mb-20 border-b-4 pb-2 w-fit m-auto border-[#DB9A48] '>Services we provide</h1> */}
      <div className='grid grid-cols-1 sm:grid-cols-2 gap-3 md:gap-10 '>
        <ServiceCard img={edu} title="Educational Projects" link="/educational-projects" text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. "/>
        <ServiceCard img={sw} title="Social Welfare Projects" link="/social-welfare-projects" text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. "/>
      </div>
    </div>
  )
}

export default ServicesProvide
