import React, { useEffect } from 'react'
import ProjectCard from '../layouts/ProjectCard'
import project_img2 from '../assets/projects/ilight.jpg'
import project_img3 from '../assets/projects/ascend.jpg'
import project_img4 from '../assets/projects/abacus.jpg'
import project_img5 from '../assets/projects/pathway_excom.jpg'
import project_img6 from '../assets/gallery/pathway_gallery_7.webp'

const EduProjects = () => {

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  return (
    <div className='mt-32'>
      <h1 className="text-center text-2xl min-[350px]:text-3xl mb-10 sm:mb-20 border-b-4 pb-2 w-fit m-auto border-[#DB9A48]">Educational Projects</h1>

      <div className='mx-5 min-[500px]:mx-14 sm:mx-10 md:mx-20 lg:mx-40'>
        <div className='mb-10'><ProjectCard img={project_img2} title="iLight Edu Mission" text="An educational program extending from 7th grade to +2, guiding students to higher levels, fostering divine thoughts and moral consciousness to create a virtuous generation. Currently, 62 students are enrolled in this program across 3 batches."/></div>
        <div className='mb-10'><ProjectCard img={project_img3} title="Ascend" text="An educational program that provides expert training to help students prepare for competitive exams and qualify for higher studies in prestigious universities in the country, along with their regular studies. Currently, 20 students are enrolled in this program."/></div>
        <div className='mb-10'><ProjectCard img={project_img4} title="Abacus" text="An educational program that provides training to achieve focus, self-confidence, personality development, creative intellectual growth, logical intelligence, memory power, and concentration, leading to academic excellence. It equips the current generation to master mental calculations at a speed that surpasses calculators."/></div>
        <div className='mb-10'><ProjectCard img={project_img5} title="ExCom" text="A special training program initiated for young men and women preparing for competitive exams such as KPSC and SSC. Coaching is provided under the guidance of experienced teachers."/></div>
        <div className='mb-10'><ProjectCard img={project_img6} title="Educational Scholarship Scheme" text="Scholarships for underprivileged students conducting advanced education programs."/></div>
      </div>
    </div>
  )
}

export default EduProjects
