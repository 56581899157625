import React from 'react';
import Carousel from '../layouts/Carousel';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className='-mt-10 sm:mt-0'>
      <Carousel />

      <div className='mt-10 bg-gray-950 text-white mx-5 min-[450px]:mx-10 sm:mx-20 md:mx-32 lg:mx-40 rounded-xl p-5 md:p-10'>
        <h1 className='text-xl sm:text-2xl md:text-3xl text-center'>
          Support our mission to provide education and social welfare projects. Your donation can make a difference.
        </h1>
        <div className='w-fit m-auto mt-5 sm:mt-10'>
          <Link to='/donate'>
            <button className="bg-white text-[#DB9A48] px-4 py-2 rounded-xl hover:bg-opacity-75 transition duration-300">
              Donate
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
