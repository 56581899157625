import React from 'react';

const Modal = ({ image, closeModal }) => {
  if (!image) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="relative bg-white p-4 rounded-lg shadow-lg max-w-lg">
        <button
          className="absolute top-2 right-2 bg-[#DB9A48] text-white px-2 text-3xl rounded-md hover:bg-[#bf8538] transition duration-300"
          onClick={closeModal}
        >
          &times;
        </button>
        <img
          src={image}
          alt="Selected"
          className="rounded-lg border-4 border-[#DB9A48] w-full"
        />
      </div>
    </div>
  );
};

export default Modal;
