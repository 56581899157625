import React from 'react';

const ContactCard = ({ title, navigateIcon, socialIcon }) => {
  return (
    <div
      className='p-3 sm:p-6 border rounded-xl w-full md:w-[80%] m-auto text-center bg-black text-white hover:scale-105 cursor-pointer transition duration-300'
    >
        <div className=''>
        {socialIcon}
        </div>
      <h2 className='sm:text-xl md:text-2xl mt-3 sm:mt-5 mb-2 sm:mb-4'>{title}</h2>
      <div className='flex justify-center items-center space-x-4 '>
        {navigateIcon}
      </div>
    </div>
  );
};

export default ContactCard;
