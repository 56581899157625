import React, { useEffect } from 'react';

const Events = () => {

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })
  
  return (
    <div className="mt-10 flex justify-center items-center h-screen">
      <p className="text-center text-3xl sm:text-4xl font-semibold blink-text">Coming Soon...</p>
    </div>
  );
}

export default Events;
