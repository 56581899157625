import React from 'react'
import Hero from '../components/Hero'
import WhoWeAre from '../components/WhoWeAre'
import ServicesProvide from '../components/ServicesProvide'

const Home = () => {
  return (
    <div className='mt-20'>
      <Hero />
      <WhoWeAre />
      <ServicesProvide />
    </div>
  )
}

export default Home
