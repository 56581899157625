import React, { useEffect } from 'react';
import { FaArrowRight, FaFacebook, FaEnvelope, FaPhone } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { IoLogoWhatsapp } from 'react-icons/io';
import { IoMail } from 'react-icons/io5';
import ContactCard from '../layouts/ContactCard';
import ContactForm from '../components/ContactForm';

const Contact = () => {

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className='mt-32 '>
      <h1 className='text-center text-3xl mb-14 sm:mb-20 border-b-4 pb-2 w-fit m-auto border-[#DB9A48]'>Contact Us</h1>

      <div className='w-[95%] sm:w-[70%] m-auto grid grid-cols-3 justify-center gap-1 sm:gap-5 mb-20'>
        <Link to='https://wa.me/+919605904712'>
          <ContactCard
            title='Chat us'
            socialIcon={<IoLogoWhatsapp className='text-[#fffff] m-auto w-7 sm:w-14 md:w-20 h-7 sm:h-14 md:h-20' />}
            navigateIcon={<FaArrowRight className='w-7 sm:w-10 h-7 sm:h-10' />}
          />
        </Link>
        <Link to='mailto:pathwayfoundation2020@gmail.com'>
          <ContactCard
            title='Email us'
            socialIcon={<FaEnvelope className='text-[#fffff] m-auto w-7 sm:w-14 md:w-20 h-7 sm:h-14 md:h-20' />}
            navigateIcon={<FaArrowRight className='w-7 sm:w-10 h-7 sm:h-10' />}
          />
        </Link>
        <Link to='tel:+919605904712'>
          <ContactCard
            title='Call us'
            socialIcon={<FaPhone className='text-[#fffff] m-auto w-7 sm:w-14 md:w-20 h-7 sm:h-14 md:h-20' />}
            navigateIcon={<FaArrowRight className='w-7 sm:w-10 h-7 sm:h-10' />}
          />
        </Link>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-20 mb-20 mx-5 min-[400px]:mx-10 sm:mx-20'>
        <ContactForm /> 
        <div className='space-y-4 md:ms-10 lg:ms-20 text-sm sm:text-base'>
          <p className='text-xl font-semibold mb-10'>Get in Touch</p>
          <p><span className='font-semibold'>Phone: </span>+91 9605904712</p>
          <p><span className='font-semibold'>Email: </span>pathwayfoundation2020@gmail.com</p>
          <p><span className='font-semibold'>Address: </span>Palath, Kozhikode, Kerala, India - 673611</p>
          <div className='flex space-x-4'>
            <Link to='https://www.facebook.com/profile.php?id=100081340594711'><FaFacebook className='text-2xl' /></Link>
            <Link to='mailto:pathwayfoundation2020@gmail.com'><IoMail className='text-2xl' /></Link>
            <Link to='https://wa.me/+919605904712'><IoLogoWhatsapp className='text-2xl' /></Link>
          </div>
        </div>
      </div>

      <div className='mx-5 min-[400px]:mx-10 sm:mx-20'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d488.9870853695658!2d75.8306096349182!3d11.34225660009334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65df41e322449%3A0x6a30f1d7712c655a!2sPathway%20Foundation!5e0!3m2!1sen!2sin!4v1721210428075!5m2!1sen!2sin"
          width="100%"
          height="450"
          className='border-[#DB9A48] border'
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
      </div>
    </div>
  );
};

export default Contact;