import React, { useState } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { AiOutlineMenu } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import logo from '../../assets/pathway_logo.webp';
import Footer from '../Footer';
import Menu from '../Menu'; 

const Navbar = () => {
  const [isProjectsOpen, setIsProjectsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); 

  const handleProjectsHover = (isHovered) => {
    setIsProjectsOpen(isHovered);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); 
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <nav className="bg-white shadow-sm fixed top-0 w-full z-20">
        <div className="container mx-auto px-4 py-2 flex justify-between items-center">
          <div className="flex items-center">
            <img src={logo} alt="logo" className="h-14 sm:h-16" />
          </div>

          <div className="flex space-x-4 items-center">
            <ul className="hidden md:flex space-x-8">
              <li className="relative group z-10">
                <NavLink to="/" className="transition duration-300 hover:text-[#DB9A48]">
                  Home
                  <span className="block h-0.5 w-full bg-[#DB9A48] transform translate-y-1 scale-x-0 group-hover:scale-x-100 group-hover:translate-y-0 transition-all duration-300"></span>
                </NavLink>
              </li>
              <li
                className="relative group"
                onMouseEnter={() => handleProjectsHover(true)}
                onMouseLeave={() => handleProjectsHover(false)}
              >
                <button className={`transition duration-300 flex items-center ${isProjectsOpen ? 'text-[#DB9A48]' : ''} `}>
                  Projects
                  <svg
                    className={`w-4 h-4 ml-1 ${isProjectsOpen ? 'transform rotate-180 transition-transform duration-300' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
                {isProjectsOpen && (
                  <ul className="absolute -left-20 w-60 pt-2 text-center bg-white shadow-sm z-0">
                    <li>
                      <NavLink to="/educational-projects" className="block px-4 py-2 hover:text-[#DB9A48]">
                        Educational Projects
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/social-welfare-projects" className="block px-4 py-2 hover:text-[#DB9A48]">
                        Social Welfare Projects
                      </NavLink>
                    </li>
                  </ul>
                )}
                <span className="block h-0.5 w-full bg-[#DB9A48] transform translate-y-1 scale-x-0 group-hover:scale-x-100 group-hover:translate-y-0 transition-all duration-300"></span>
              </li>
              {/* <li className="relative group z-10">
                <NavLink to="/events" className="transition duration-300 hover:text-[#DB9A48]">
                  Events
                  <span className="block h-0.5 w-full bg-[#DB9A48] transform translate-y-1 scale-x-0 group-hover:scale-x-100 group-hover:translate-y-0 transition-all duration-300"></span>
                </NavLink>
              </li> */}
              <li className="relative group">
                <NavLink to="/gallery" className="transition duration-300 hover:text-[#DB9A48]">
                  Gallery
                  <span className="block h-0.5 w-full bg-[#DB9A48] transform translate-y-1 scale-x-0 group-hover:scale-x-100 group-hover:translate-y-0 transition-all duration-300"></span>
                </NavLink>
              </li>
              <li className="relative group">
                <NavLink to="/about" className="transition duration-300 hover:text-[#DB9A48]">
                  About
                  <span className="block h-0.5 w-full bg-[#DB9A48] transform translate-y-1 scale-x-0 group-hover:scale-x-100 group-hover:translate-y-0 transition-all duration-300"></span>
                </NavLink>
              </li>
              <li className="relative group">
                <NavLink to="/contact" className="transition duration-300 hover:text-[#DB9A48]">
                  Contact
                  <span className="block h-0.5 w-full bg-[#DB9A48] transform translate-y-1 scale-x-0 group-hover:scale-x-100 group-hover:translate-y-0 transition-all duration-300"></span>
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="hidden md:flex">
            <Link
              to="/donate"
              className="bg-[#DB9A48] text-white px-5 py-2 rounded-xl hover:bg-white hover:text-[#DB9A48] border border-[#DB9A48] transition duration-300"
            >
              Donate
            </Link>
          </div>

          <button className="md:hidden" onClick={toggleMenu}>
            {isMenuOpen ? <IoCloseOutline className="h-8 w-8" /> : <AiOutlineMenu className="h-6 w-6" />}
          </button>
        </div>
      </nav>

      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'} bg-white shadow-md`}>
        <Menu closeMenu={closeMenu} />
      </div>

      <Outlet />
      <Footer />
    </>
  );
};

export default Navbar;
