import React from 'react';

function TariffTable() {
  const tableData = [
    {
      program: 'Health Care',
      description: 'Average per month 300 patients',
      monthlyCost: '₹50000 per month',
    },
    {
      program: 'Home Care',
      description: 'Average per month 150 patients',
      monthlyCost: '₹60000 per month',
    },
    {
      program: 'Ambulance Service',
      description: 'Free service to 30 financially distressed patients per month',
      monthlyCost: '₹40000 per month',
    },
    {
      program: 'Relief Activities',
      description: 'Average per month food kits are given to 10 families',
      monthlyCost: '₹10000 per month',
    },
    {
      program: 'Pleasure Home',
      description: '15 houses a year repairs are complete doing',
      monthlyCost: '₹50000 per house',
    },
    {
      program: 'iLight Edu Mission',
      description: '83 students in four batches',
      monthlyCost: '₹37000 per month',
    },
    // {
    //   program: 'qbic',
    //   description: 'Two years of Quran Hifdcourse. 37 students',
    //   monthlyCost: '₹40000 per month',
    // },
    {
      program: 'High Care',
      description: 'Enables higher studies method of study. 30 students',
      monthlyCost: '₹15000 per month',
    },
    {
      program: 'Other Expenses',
      description: 'Office and scholarship related expenses',
      monthlyCost: '₹20000 per month',
    },
  ];

  return (
    <div className=" mt-16 mb-8">
        <h1 className='mt-20 text-center text-3xl mb-10 border-b-4 pb-2 w-fit m-auto border-[#DB9A48] '>Tariff of Sponsorship</h1>

      <h2 className=" mx-5 text-xl min-[350px]:text-2xl sm:text-3xl font-semibold text-[#DB9A48] mb-5 sm:mb-10 text-center">See How Your Donation Makes an Impact</h2>
      <table className="w-[95%] sm:w-[80%] text-gray-700 m-auto  table-auto  px-4">
        <thead>
          <tr className="bg-gray-950 text-white text-left">
            <th className="p-2">Program</th>
            <th className="p-2">Description</th>
            <th className="p-2">Cost</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row) => (
            <tr key={row.program} className="border-b">
              <td className="p-2">{row.program}</td>
              <td className="p-2">{row.description}</td>
              <td className="p-2">{row.monthlyCost}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TariffTable;
