import React, { useEffect } from 'react';
import about from '../assets/carousel1.webp'
import mission from '../assets/mission.webp'
import vision from '../assets/vision.webp'

const About = () => {

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  return (
    <div className=" mt-32 bg-gray-50">
      <h1 className='text-center text-3xl mb-10 sm:mb-20 border-b-4 pb-2 w-fit m-auto border-[#DB9A48]'>About Us</h1>

      <div className="container mx-auto px-6 md:px-12 xl:px-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div>
            <img 
              src={about} 
              alt="Pathway Foundation" 
              className="rounded-xl"
            />
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-lg  mb-4">
              <span className='text-[#DB9A48] font-semibold'>Pathway Foundation</span>, based in Palath, Chelnannur Panchayath, Calicut, Kerala, is a beacon of hope and empowerment established in 2020. As a registered educational and charitable trust, we are dedicated to transforming lives through a blend of educational excellence and comprehensive health support. Our initiatives span from primary to degree-level education, coupled with essential health services such as free clinics, home care, medicine distribution, and food kits. Driven by the support of our generous well-wishers and donors, we strive to create lasting positive change in our community.
            </p>
          </div>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-10 mt-20 mx-5  sm:mx-20 '>
          <div className='border border-black p-5 rounded-2xl'>
            <img src={mission} alt="" className='mb-5 rounded-xl md:h-72 w-full'/>
            <h3 className="text-3xl font-semibold text-[#DB9A48] mb-5 text-center">Our Mission</h3>
            <p className="text-lg mb-4">
              Our mission is to empower individuals and uplift communities by providing quality education and accessible healthcare. We aim to nurture young minds, support academic achievements, and ensure the well-being of those in need through our comprehensive programs.
            </p>
          </div>
          <div className='border border-black p-5 rounded-2xl'>
            <img src={vision} alt="" className='rounded-xl mb-5 md:h-72 w-full' />
          <h3 className="text-3xl font-semibold text-[#DB9A48] mb-5 text-center">Our Vision</h3>
            <p className="text-lg mb-4">
              We envision a society where every individual has the opportunity to reach their full potential through education and health support. By fostering a culture of learning and compassion, we aspire to create a brighter, healthier, and more equitable future for all.
            </p>
          </div>
        </div>

      </div>
    </div>
  );
}

export default About;
