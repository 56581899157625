import React, { useEffect, useRef } from 'react';
import TariffTable from '../layouts/TariffTable';
import donate_img1 from '../assets/don2.webp';
import donate_img2 from '../assets/don3.webp';
import donation_qr from '../assets/donation_qr_pathway_foundation.webp';
import { FaAnglesDown } from "react-icons/fa6";

const Donate = () => {
  const donationSectionRef = useRef(null);

  const handleDonateNowClick = () => {
    donationSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  return (
    <div className='mt-32 relative'>
      <h1 className='text-3xl min-[450px]:text-5xl sm:text-6xl lg:text-7xl font-semibold min-[450px]:font-normal text-center mx-10 min-[400px]:mx-20 md:mx-40 lg:mx-60'>Support us and empower individuals</h1>
      <div className='relative w-[90%] md:w-[75%] mx-auto mt-10 grid grid-cols-1 md:grid-cols-2 gap-5'>
        <img src={donate_img1} alt="Donation" className='rounded-xl w-full h-80' />
        <img src={donate_img2} alt="Donation" className='rounded-xl w-full h-80' />
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black text-white p-5 rounded-xl flex flex-col items-center'>
          <button className="text-lg font-semibold mb-2" onClick={handleDonateNowClick}>
            Donate now
          </button>
          <FaAnglesDown className='pulse-button text-white bg-[#DB9A48] px-4 py-2 rounded-xl w-14 h-14' onClick={handleDonateNowClick} />
        </div>
      </div>

      <TariffTable />

      <h1 ref={donationSectionRef} className='mt-20 text-center text-2xl min-[350px]:text-3xl mb-20 border-b-4 pb-2 w-fit mx-auto border-[#DB9A48]'>Make a difference today</h1>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-10 gap-y-20 mx-5 md:mx-20'>
        <div>
          <p className='text-center text-lg font-semibold mb-5 md:mb-10'>Scan QR Code</p>
          <img src={donation_qr} alt="QR Code" className='w-[60%] mx-auto' />
        </div>
        <div>
          <p className='text-center text-lg font-semibold mb-5 md:mb-10'>Bank Transfer</p>
          <div className='text-lg font-bold mt-5 md:mt-10 p-10'>
            <p className='mb-5'>Name: Pathway Foundation</p>
            <p className='mb-5'>Account Number: 13890200013422</p>
            <p className='mb-5'>IFSC Code: FDRL0001389</p>
            <p className='mb-5'>Bank Name: Federal Bank</p>
            <p className='mb-5'>Branch: SM Street Branch, Calicut</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Donate;
