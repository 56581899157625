import React from 'react';
import Swal from 'sweetalert2'

const ContactForm = () => {

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
    
        formData.append("access_key", "691e68cc-6949-421b-8368-41297317cccd");
    
        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);
    
        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: json
        }).then((res) => res.json());
    
        if (res.success) {
          Swal.fire({
            title: 'Success!',
            text: 'Message sent successfully!',
            icon: 'success',
            confirmButtonText: 'OK'
          })
        }
      };

  return (
    <div>
      <h1 className='text-xl font-semibold mb-5 text-center'>Send a message</h1>
      <form onSubmit={onSubmit} className='space-y-4'>
        <input
          type="text" name='name' placeholder='Name'
          className='w-full p-2 border rounded'
          required
        />
        <input
          type="email" name='email' placeholder='Email'
          className='w-full p-2 border rounded'
          required
        />
        <textarea
          type="text" name='message' placeholder='Message'
          className='w-full p-2 border rounded'
          rows='4'
          required
        />
        <button type='submit' className='w-full p-2 bg-[#DB9A48] text-white rounded'>
          Send
        </button>
      </form>
    </div>
  );
};

export default ContactForm;