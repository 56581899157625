import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import img1_desktop from '../assets/carousel/PAGE 1 - DESKTOP.webp';
import img1_tablet from '../assets/carousel/PAGE 1 - TABLET.webp';
import img1_mobile from '../assets/carousel/PAGE 1 - MOBILE.webp';
import img2_desktop from '../assets/carousel/PAGE - 2 DESKTOP.webp';
import img2_tablet from '../assets/carousel/PAGE - 2 TABLET.webp';
import img2_mobile from '../assets/carousel/PAGE - 2 MOBILE.webp';
import img3_desktop from '../assets/carousel/PAGE - 3 DESKTOP.webp';
import img3_tablet from '../assets/carousel/PAGE - 3 TABLET.webp';
import img3_mobile from '../assets/carousel/PAGE - 3  MOBILE.webp';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';

export default function App() {
  const [screenSize, setScreenSize] = useState('desktop');

  useEffect(() => {
    const updateScreenSize = () => {
      const width = window.innerWidth;
      if (width < 960) {
        setScreenSize('mobile');
      } else if (width >= 960 && width < 1080) {
        setScreenSize('tablet');
      } else {
        setScreenSize('desktop');
      }
    };

    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();

    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  const getImage = (imgDesktop, imgTablet, imgMobile) => {
    if (screenSize === 'mobile') return imgMobile;
    if (screenSize === 'tablet') return imgTablet;
    return imgDesktop;
  };

  return (
    <div className="relative mt-28 sm:mt-20 md:mt-0 ">
      <Swiper
        spaceBetween={30}
        effect="fade"
        navigation={false}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        className="h-full"
      >
        <SwiperSlide className="h-full">
          <div className="h-full w-full">
            <img src={getImage(img1_desktop, img1_tablet, img1_mobile)} className="object-cover h-full w-full" alt="Slide 1" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="h-full">
          <div className="h-full w-full">
            <img src={getImage(img2_desktop, img2_tablet, img2_mobile)} className="object-cover h-full w-full" alt="Slide 2" />
          </div>
        </SwiperSlide>
        <SwiperSlide className="h-full">
          <div className="h-full w-full">
            <img src={getImage(img3_desktop, img3_tablet, img3_mobile)} className="object-cover h-full w-full" alt="Slide 3" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}