import React from 'react';

const ProjectCard = (props) => {
  return (
    <div className="relative md:grid grid-cols-2 items-center bg-clip-border group shadow md:shadow-none w-full  flex-row">
      <div className="relative  m-0 overflow-hidden  p-3 bg-clip-border shrink-0">
        <img
          src={props.img}
          alt="card-image"
          className="object-cover w-full  shadow  h-60 sm:h-96  "
        />
      </div>
      <div className="p-6">
        <h4 className="block  mb-5 sm:mb-10 font-sans text-2xl sm:text-4xl antialiased  leading-snug tracking-normal ">
          {props.title}
        </h4>
        <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed ">
          {props.text}
        </p>
      </div>
    </div>
  );
};

export default ProjectCard;
