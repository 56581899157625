import React, { useEffect } from 'react'
import ProjectCard from '../layouts/ProjectCard'
import project_img1 from '../assets/projects/pathway_ambulance.webp'
import project_img2 from '../assets/projects/pathway_homecare.jpeg'
import project_img3 from '../assets/projects/pathway_physiotherapy.jpeg'
import project_img4 from '../assets/projects/pathway_volunteer.webp'
import project_img6 from '../assets/projects/pathway_councelling.webp'

const SWProjects = () => {

  useEffect(()=>{
    window.scrollTo({ top: 0});
  })

  return (
    <div className='mt-32'>
      <h1 className="text-center text-2xl min-[350px]:text-3xl mb-10 sm:mb-20 border-b-4 pb-2 w-fit m-auto border-[#DB9A48]">Social Welfare Projects</h1>

      <div className='mx-5 min-[500px]:mx-14 sm:mx-10 md:mx-20 lg:mx-40'>
        <div className='mb-10'><ProjectCard img={project_img1} title="Ambulance Service" text="A service utilizing two ambulances to transport accident victims or patients to treatment centers. The service is available 24/7 with dedicated volunteer drivers. For those experiencing financial difficulties, the service is entirely free. During emergencies, the services of doctors and nurses are also available."/></div>
        <div className='mb-10'><ProjectCard img={project_img2} title="Home Care" text="With the goal of providing physical and mental reassurance to hundreds of bedridden patients suffering from chronic illnesses at home, medical care and attention are offered. This service is provided by trained caregivers who have completed their training at the Medical College, Kozhikode."/></div>
        <div className='mb-10'><ProjectCard img={project_img3} title="Health Care Clinic and Physiotherapy Centre" text="In addition to medical treatment, a system is established to assist individuals facing financial hardships, providing free medical care to hundreds of bedridden patients. This includes daily nursing care, meal distribution, free medical camps, and physiotherapy services."/></div>
        <div className='mb-10'><ProjectCard img={project_img4} title="Volunteer Services" text="Physical assistance for construction activities, cleanliness in hospitals, schools, public places. Blood donation. Food distribution. Building construction projects, meaningful participation in social organizations."/></div>
        <div className='mb-10'><ProjectCard img={project_img6} title="Councelling Centre" text="Mental resilience is crucial not only for physical health but also for preserving mental strength against various illnesses. It is particularly essential in overcoming challenges such as sadness, anxiety, irrational fears, cognitive distortions, existential issues, family problems, educational stressors, and psychological trauma. Skilled counselors provide invaluable support and service to those experiencing these difficulties."/></div>
      </div>
    </div>
  )
}

export default SWProjects
