import React from 'react';
import { FaFacebook } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';
import { IoMail } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="mt-20 sm:mt-32 bg-gray-950 text-white py-8">
      <div className="container mx-auto px-4 grid grid-cols-1 min-[900px]:grid-cols-3 gap-8">
        <div>
          <h2 className="text-2xl font-bold mb-5">Pathway Foundation</h2>
          <p className="mb-5 mr-10">
            Every donation brings hope and opportunity to those in need.
          </p>
          <Link to='/donate'><button className="bg-white text-[#DB9A48] px-4 py-2 rounded-xl hover:bg-opacity-75 transition duration-300">
            Donate
          </button></Link>
          
        </div>

        <div className='min-[900px]:m-auto'>
          <h2 className="text-xl font-bold mb-4 ">Quick Links</h2>
          <nav>
            <ul className="space-y-2 ">
              <li>
                <Link to='/' className="hover:underline">Home</Link>
              </li>
              <li>
                <Link to='/educational-projects' className="hover:underline">Educational Projects</Link>
              </li>
              <li>
                <Link to='/social-welfare-projects' className="hover:underline">Social Welfare Projects</Link>
              </li>
              <li>
                <Link to='/gallery' className="hover:underline">Gallery</Link>
              </li>
              <li>
                <Link to='/about' className="hover:underline">About</Link>
              </li>
              <li>
                <Link to='/contact' className="hover:underline">Contact</Link>
              </li>
            </ul>
          </nav>
        </div>

        <div>
          <h2 className="text-xl font-bold mb-4">Contact Us</h2>
          <p className="mb-2">
            <strong>Phone:</strong> +91 9605904712
          </p>
          <p className="mb-2">
            <strong>Email:</strong> pathwayfoundation2020@gmail.com
          </p>
          <p>
          Palath, Kozhikode, Kerala, India - 673611
          </p>
          <div className='flex space-x-4 mt-5'>
            <Link to='https://www.facebook.com/profile.php?id=100081340594711'><FaFacebook className='text-2xl text-white'/></Link>
            <Link to='mailto:pathwayfoundation2020@gmail.com'><IoMail className='text-2xl text-white'/></Link>
            <Link to='https://wa.me/+919605904712'><IoLogoWhatsapp className='text-2xl text-white'/></Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
