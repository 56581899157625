import React from 'react';
import { Link } from 'react-router-dom';

const ServiceCard = (props) => {
  return (
    <Link to={props.link} >
    <div className="relative w-full h-full group rounded-xl overflow-hidden ">
      <img
        src={props.img}
        alt="card-image"
        className="object-cover w-full h-full group-hover:scale-110 transition duration-300"
      />
      <div className="absolute bottom-3 left-1/2 transform -translate-x-1/2 w-[95%]  rounded-xl bg-white py-2 px-4 flex justify-between items-center">
        <h4 className="font-sans text-lg sm:text-xl  font-semibold">
          {props.title}
        </h4>
          <button
            className="p-1  font-sans text-sm font-bold text-center align-middle transition-all rounded-full select-none bg-[#DB9A48] text-white"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              className="w-6 h-6 sm:w-8 sm:h-8 group-hover:-rotate-45 transform transition duration-200"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
            </svg>
          </button>
      </div>
    </div>
    </Link>
  );
};

export default ServiceCard;
